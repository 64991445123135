import React from 'react'
import './styles.css'

class Disconnect extends React.Component {
  constructor (props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  reset () {
    this.props.changeMode('init')
    this.props.changePageId(0)
    this.props.changeButtonId(2)
  }

  handleClick () {
    this.reset()
  }

  render () {
    document.onkeydown = function (event) {
      event = event || window.event
      if (event.keyCode === 27) {
        event.preventDefault()
        this.reset()
      }
    }.bind(this)

    return (
      <div className='error'>
        <div className='error_in'>
            SOFTWARE FAILURE<br /><br />
            Program failed (error #00000003).<br />
            Wait for disk activity to finish.<br /><br />
          <span onClick={this.handleClick}>Esc=Reboot</span>
        </div>
      </div>
    )
  }
}

export default Disconnect
