import React from 'react'
import './styles.css'
import Button from '../Button'
import Logo from '../Logo'
import Mailer from '../Mailer'
import Disconnect from '../Disconnect'

class Page extends React.Component {
  selectPage (pageId = 0) {
    let buttons = ['(B)ack']

    switch (pageId) {
      case 0:
        buttons = [
          '',
          '',
          '(I)ntroduction',
          '(A)vailable Services',
          '(T)echnologies',
          '(S)end a message',
          '(G)o to source',
          '(D)isconnect'
        ]
        return (
          <div className='main-box'>
            {this.renderButtons(buttons)}
            <div className='help'>Use arrow keys to navigate. Double click or press a corresponding letter to access a page.</div>
          </div>
        )
      case 1:
        window.location.href = 'https://bitbucket.org/_nanttis/akotek-home/'
        return false
      case 2:
        return (
          <div className='main-box'>
            <div className='page-content'>
              <Logo />
            </div>
            {this.renderButtons(buttons)}
          </div>
        )
      case 3:
        return (
          <div className='main-box'>
            <div className='page-content'>
              <ul>
                <li>
                  <p>
                    <span className='text-white'>Akotek</span> is a consultation company focusing on most aspects around web services. We offer:
                  </p>
                </li>
                <li>
                  Web systems development
                  <p>
                    Consultation or development of custom web applications or larger systems for eCommerce, library services, integrated solutions, etc.
                  </p>
                </li>
                <li>
                  Cloud services
                  <p>
                    Design or implementation of public or private cloud services.
                  </p>
                </li>
                <li>
                  Automation
                  <p>
                    Scripting to deliver data or functionality.
                  </p>
                </li>
                <li>
                  IT infrastructure / Networks
                  <p>
                    Office or server network consultation, common web service related tools and components.
                  </p>
                </li>
              </ul>
            </div>
            {this.renderButtons(buttons)}
          </div>
        )
      case 4:
        return (
          <div className='main-box'>
            <div className='page-content'>
              <ul>
                <li>
                  <p>
                    This BBS has been constructed using various technologies. All code throughout the system is Javascript.
                  </p>
                </li>
                <li>
                  React.js
                  <p>
                    A Javascript framework for creating interactive web applications. The system consists of many components which are bundled into a single application.
                  </p>
                </li>
                <li>
                  Redux
                  <p>
                    A state management library for Javascript applications. This is used for changing pages and managing selections in the menu.
                  </p>
                </li>
                <li>
                  Vis.js
                  <p>
                    A graphics visualization library for Javascript applications. The CRT and glitch animations and such filters are done with this.
                  </p>
                </li>
                <li>
                  GSAP
                  <p>
                    An animation library. Used for some animations on this BBS.
                  </p>
                </li>
                <li>
                  Typed.js
                  <p>
                    For the helpful connection assistant. How else would you know what to type?
                  </p>
                </li>
                <li>
                  Node.js, Webpack, Babel
                  <p>
                    Node.js is a JavaScript runtime environment that executes JavaScript code outside of a web browser. This powers the whole development process. Webpack and Babel together transform the development code into browser compatible standard Javascript code.
                  </p>
                </li>
              </ul>
            </div>
            {this.renderButtons(buttons)}
          </div>
        )
      case 5:
        !this.props.isFormSent ? buttons = ['(B)ack', '(S)end'] : buttons = ['(B)ack']
        return (
          <div className='main-box'>
            <div className='page-content'>
              <Mailer {...this.props} />
            </div>
            {this.renderButtons(buttons, 1)}
          </div>
        )
      case 6:
        buttons = ['(B)ack', '(S)ource']
        return (
          <div className='main-box'>
            <div className='page-content'>
              <p>
                The source code of this BBS is stashed away at BitBucket.org. Feel free to dig around at bitbucket.org/_nanttis/akotek-home.
              </p>
            </div>
            {this.renderButtons(buttons)}
          </div>
        )
      case 7:
        return (
          <Disconnect {...this.props} />
        )
      default:
        return false
    }
  }

  renderButtons (buttons, submitButton = false) {
    return (
      <div className='button-container'>
        {buttons.map((name, index) => {
          if (name.length > 0) {
            return <Button buttonId={this.props.buttonId} key={index} id={index} name={name} submitButton={submitButton} />
          } else {
            return null
          }
        })}
      </div>
    )
  }

  render () {
    return this.selectPage(this.props.pageId)
  }
}

export default Page
