export const CHANGE_BUTTON_ID = 'CHANGE_BUTTON_ID'
export const CHANGE_PAGE_ID = 'CHANGE_PAGE_ID'
export const LOADING = 'LOADING'
export const CHANGE_MODE = 'CHANGE_MODE'
export const SET_FORM_SENT = 'SET_FORM_SENT'

export const changeButtonId = id => ({
  type: CHANGE_BUTTON_ID,
  payload: id
})

export const changePageId = id => ({
  type: CHANGE_PAGE_ID,
  payload: id
})

export const loading = loading => ({
  type: LOADING,
  payload: loading
})

export const changeMode = mode => ({
  type: CHANGE_MODE,
  payload: mode
})

export const setFormSent = value => ({
  type: SET_FORM_SENT,
  payload: value
})
