import React from 'react'
import './App.css'
import Container from './components/Container'
import Loading from './components/Loading'
import { CSSTransition } from 'react-transition-group'
import { connect } from 'react-redux'
import { changeMode } from './redux/actions'

class App extends React.Component {
  componentDidMount () {
    window.addEventListener('orientationchange', event => {
      window.location.reload()
    }, false)

    /* let resizeTimeout
    window.addEventListener('resize', (event) => {
      clearTimeout(resizeTimeout)
      resizeTimeout = setTimeout(function () {
        window.location.reload()
      }, 1000)
    }) */

    document.cookie.includes('state=menu') && this.props.changeMode('menu')
  }

  render () {
    return (
      document.cookie.includes('state=menu') || this.props.mode === 'menu'
        ? <CSSTransition in timeout={1} classNames='show' appear>
          <Container />
          </CSSTransition>
        : <Loading />
    )
  }
}

export default connect(
  state => ({
    mode: state.mode
  }),
  dispatch => ({
    changeMode: (value) => {
      dispatch(changeMode(value))
    }
  }))(App)
