import React from 'react'
import './styles.css'
import { gsap } from 'gsap'

import { connect } from 'react-redux'
import { setFormSent } from '../../../redux/actions'

class Mailer extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      done: false,
      response: null
    }

    this.formRef = React.createRef()
    this.textArea = React.createRef()

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleFocusIn = this.handleFocusIn.bind(this)
    this.handleFocusOut = this.handleFocusOut.bind(this)
  }

  handleSubmit (event) {
    event.preventDefault()
    document.removeEventListener('submit', this.handleSubmit)

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ data: this.textArea.current.value })
    }
    fetch('https://akotek.fi/send.php', requestOptions)
      .then(async response => {
        const data = await response.json()
        if (!response.ok) {
          const error = (data && data.message) || response.status
          return Promise.reject(error)
        }
        this.setState({ response: data.response })
      })
      .catch(error => {
        console.error('There was an error!', error)
      })

    this.setState({ done: true })
    this.props.setFormSent(true)
    this.props.changeButtonId(0)
  }

  handleFocusIn () {
    document.removeEventListener('keyup', this.props.onKeyPressHandler, true)
  }

  handleFocusOut () {
    document.addEventListener('keyup', this.props.onKeyPressHandler, true)
  }

  componentDidMount () {
    document.addEventListener('submit', this.handleSubmit)
    this.props.onHandleFormRef(this.formRef)
  }

  componentDidUpdate () {
    gsap.to('.thumb', {
      duration: 0.8,
      color: 'rgba(255, 255, 255, 1)',
      transform: 'scale(1.2)',
      ease: 'steps(8)',
      repeatDelay: 1,
      repeat: -1,
      yoyo: true
    })
  }

  componentWillUnmount () {
    document.removeEventListener('submit', this.handleSubmit)
    this.state.response &&
    setTimeout(() => {
      this.props.setFormSent(false)
    }, 60000)
  }

  renderForm () {
    document.onkeydown = function (event) {
      event = event || window.event
      if (event.keyCode === 9 && this.textArea.current) {
        event.preventDefault()
        if (document.activeElement === this.textArea.current) {
          this.textArea.current.blur()
          this.props.changeButtonId(0)
        } else {
          this.textArea.current.focus()
          this.props.changeButtonId(10)
        }
      }
    }.bind(this)

    const waitAWhile = this.props.isFormSent && 'To send another message, wait a while.'

    return (
      <form id='contact-form' onSubmit={this.handleSubmit} method='POST' ref={this.formRef}>
        <div className='form'>
          <label htmlFor='message'>What is on your mind?</label>
          <textarea id='message' name='message' rows='5' ref={this.textArea} onFocus={this.handleFocusIn} onBlur={this.handleFocusOut} />
        </div>
        <div>Press 'tab' to enter or exit the input field.</div>
        <div>{waitAWhile}</div>
      </form>
    )
  }

  mailSuccess () {
    const thumb =
    `
      _         
    / (|        
   (   :        
  __\\   \\  ____
(____)   \`|    
(____)    |    
(____) .__|    
 (___)___.|____
    `
    return (
      <>
        <pre className='thumb center'>
          {thumb}
        </pre>
        <p className='center'>
        Thanks! Your message was sent.
        </p>
      </>
    )
  }

  renderSent () {
    const result = (type) => {
      return {
        true: this.mailSuccess(),
        false: 'Sorry, there appears to be a problem in message delivery. Try again later.',
        null: 'Sending...'
      }[type]
    }
    return (
      result(this.state.response)
    )
  }

  render () {
    return this.state.done ? this.renderSent() : this.renderForm()
  }
}

export default connect(
  state => ({
    isFormSent: state.isFormSent
  }),
  dispatch => ({
    setFormSent: (value) => {
      dispatch(setFormSent(value))
    }
  }))(Mailer)
