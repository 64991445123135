import { CHANGE_PAGE_ID, CHANGE_BUTTON_ID, LOADING, CHANGE_MODE, SET_FORM_SENT } from './actions'

const initialState = {
  buttonId: 2,
  pageId: 0,
  isLoading: false,
  mode: 'init',
  isFormSent: false
}

export default function rootReducer (state = initialState, action) {
  switch (action.type) {
    case CHANGE_BUTTON_ID:
      return {
        ...state,
        buttonId: action.payload
      }
    case CHANGE_PAGE_ID:
      return {
        ...state,
        pageId: action.payload
      }
    case LOADING:
      return {
        ...state,
        isLoading: action.payload
      }
    case CHANGE_MODE: {
      const date = new Date()
      date.setTime(date.getTime() + (30 * 24 * 60 * 60 * 1000))
      const expires = date.toUTCString()
      action.payload === 'menu'
        ? document.cookie = 'state=menu; expires=' + expires + '; path=/'
        : document.cookie = 'state=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
      return {
        ...state,
        mode: action.payload
      }
    }
    case SET_FORM_SENT:
      return {
        ...state,
        isFormSent: action.payload
      }
    default:
      return state
  }
}
