import React from 'react'
import './styles.css'
import Typed from 'typed.js'

import { connect } from 'react-redux'
import { changeMode } from '../../redux/actions'

class Loading extends React.Component {
  constructor (props) {
    super(props)

    this.consoleRef = React.createRef()
    this.cursorRef = React.createRef()

    this.state = {
      history: []
    }
  }

  componentDidMount () {
    const lines = ['', '$ ', 'Unkown command', '$ ', 'Enter password: ', '', '', '', '']
    const options = {
      strings: [
        '`Welcome to akotek.fi, a web consultation company. Input a command:` ^1000\n',
        'teletn -l mort atoket.fi 4201',
        '`` ^1000\n',
        'telnet -l mort akotek.fi 4201',
        'carrot',
        '`Connecting...` ^5000\n',
        '`Connection established! Last login 04/05/1995.` ^1000',
        '`Loading interface...` ^3000'],
      typeSpeed: 90,
      startDelay: 2000,
      fadeOut: true,
      onStringTyped: (arrayPos, self) => {
        const txt = self.el.innerText
        self.el.innerText = ''
        this.setState({ history: this.state.history.concat(lines[0] + ' ' + txt) })
        this.cursorRef.current.innerText = ''
        lines.length > 0 && lines.shift()
        this.cursorRef.current.innerText = lines[0]
      },
      onComplete: (self) => {
        this.props.changeMode('menu')
      }
    }
    this.typed = new Typed(this.consoleRef.current, options)
  }

  componentWillUnmount () {
    this.typed.destroy()
  }

  render () {
    return (
      <div className='loading'>
        <div className='auto-type'>
          <div className='history'>
            {this.state.history.map((item, index) => {
              return (
                <p key={index}>{item}</p>
              )
            })}
          </div>
          <span className='cursor' ref={this.cursorRef} /> <span className='text' ref={this.consoleRef} />
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({
    mode: state.mode
  }),
  dispatch => ({
    changeMode: (value) => {
      dispatch(changeMode(value))
    }
  }))(Loading)
