import React from 'react'
import './styles.css'

class Button extends React.Component {
  render () {
    const { id, name, buttonId, submitButton } = this.props
    const active = buttonId === Number(id) ? ' active' : ''
    const [submitForm, submitType] = submitButton === Number(id) ? ['contact-form', 'submit'] : [null, null]

    return <div className={`button${active}`} id={id} form={submitForm} type={submitType}>{name}</div>
  }
}

export default Button
