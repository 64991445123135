import React from 'react'
import './styles.css'
import Page from './Page'

import { connect } from 'react-redux'
import { changeButtonId, changePageId, loading, changeMode, setFormSent } from '../../redux/actions'

class Container extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      formRef: null
    }

    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleDblclick = this.handleDblclick.bind(this)
    this.handleFormRef = this.handleFormRef.bind(this)
  }

  changePage (pageId = 0) {
    this.props.loading(true)
    setTimeout(() => {
      this.props.loading(false)
      this.props.changePageId(pageId)
      pageId === 0 ? this.props.changeButtonId(2) : this.props.changeButtonId(0)
    }, 600)
  }

  submitForm () {
    const node = this.state.formRef
    node.current.requestSubmit()
  }

  handleKeyPress (event) {
    event.preventDefault()

    const { buttonId, pageId } = this.props
    const firstMenuButtonId = 2
    const buttonsTotal = 8

    if (pageId === 7) { return }

    switch (event.keyCode) {
      case 13:
        // enter
        if (buttonId !== 1) {
          this.changePage(buttonId)
        } else {
          // special functions
          pageId === 5 && this.submitForm()
          pageId === 6 && (window.location.href = 'https://bitbucket.org/_nanttis/akotek-home/')
        }
        break
      case 38:
        // up
        pageId === 0 && buttonId >= (firstMenuButtonId + 2) &&
        this.props.changeButtonId(buttonId - 2)
        break
      case 39:
        // right
        pageId === 0 && buttonId <= (buttonsTotal - firstMenuButtonId) &&
        this.props.changeButtonId(buttonId + 1)
        // subpage
        if (pageId === 5 && buttonId === 0) {
          !this.props.isFormSent && this.props.changeButtonId(buttonId + 1)
        } else if (pageId === 6 && buttonId === 0) {
          this.props.changeButtonId(buttonId + 1)
        }
        break
      case 40:
        // down
        pageId === 0 && buttonId <= (buttonsTotal - firstMenuButtonId - 1) &&
        this.props.changeButtonId(buttonId + 2)
        break
      case 37:
        // left
        pageId === 0 && buttonId >= (firstMenuButtonId + 1) &&
        this.props.changeButtonId(buttonId - 1)
        // subpage
        if ([5, 6].includes(pageId) && buttonId === 1) {
          this.props.changeButtonId(buttonId - 1)
        }
        break
      case 73:
        // I
        if (pageId === 0) {
          this.changePage(firstMenuButtonId)
        }
        break
      case 65:
        // A
        if (pageId === 0) {
          this.changePage(firstMenuButtonId + 1)
        }
        break
      case 84:
        // T
        if (pageId === 0) {
          this.changePage(firstMenuButtonId + 2)
        }
        break
      case 83:
        // S
        if (pageId === 0) {
          this.changePage(firstMenuButtonId + 3)
        } else if (pageId === 5) {
          this.props.changeButtonId(0)
          this.submitForm()
        } else if (pageId === 6) {
          this.props.changeButtonId(1)
          window.location.href = 'https://bitbucket.org/_nanttis/akotek-home/'
        }
        break
      case 71:
        // G
        if (pageId === 0) {
          this.changePage(firstMenuButtonId + 4)
        }
        break
      case 68:
        // D
        if (pageId === 0) {
          this.changePage(firstMenuButtonId + 5)
        }
        break
      case 66:
        // B
        this.changePage(0)
        break
      default:
        break
    }
  }

  handleClick (event) {
    if (event.target.className === 'button') {
      this.props.changeButtonId(Number(event.target.id))
    }
  }

  handleDblclick (event) {
    if (event.target.className === 'button active') {
      if (event.target.attributes.form) {
        this.submitForm()
      } else {
        this.changePage(Number(event.target.id))
      }
    }
  }

  handleFormRef (ref) {
    this.setState({
      formRef: ref
    })
  }

  componentDidMount () {
    document.addEventListener('keyup', this.handleKeyPress, true)
    document.addEventListener('click', this.handleClick)
    document.addEventListener('dblclick', this.handleDblclick)
  }

  render () {
    const isLoading = this.props.isLoading ? ' loading-box-active' : ''
    return (
      <div className='wrapper'>
        <div id='header'>Welcome to Akotek Bulletin Board System</div>
        <div className='container'>
          <div className={`loading-box${isLoading}`} />
          <Page {...this.props} onKeyPressHandler={this.handleKeyPress} onHandleFormRef={this.handleFormRef} />
        </div>
        <div id='footer'>Version 1.0.13 Copyright (C) 1995 Akotek ICT</div>
      </div>
    )
  }
}

export default connect(
  state => ({
    buttonId: state.buttonId,
    pageId: state.pageId,
    isLoading: state.isLoading,
    mode: state.mode,
    isFormSent: state.isFormSent
  }),
  dispatch => ({
    changeButtonId: (id) => {
      dispatch(changeButtonId(id))
    },
    changePageId: (id) => {
      dispatch(changePageId(id))
    },
    loading: (value) => {
      dispatch(loading(value))
    },
    changeMode: (value) => {
      dispatch(changeMode(value))
    },
    setFormSent: (value) => {
      dispatch(setFormSent(value))
    }
  }))(Container)
