import * as PIXI from 'pixi.js'
import * as crt from '@pixi/filter-crt'
import * as RGBSplit from '@pixi/filter-rgb-split'

let renderer
let graphics
let stage
let oldTime
const animateProps = {
  seed: 0.5,
  noise: 0.05,
  time: 0,
  curvature: 20,
  lineWidth: window.innerHeight / 4,
  lineContrast: 0.3,
  vignetting: 0.4,
  vignettingAlpha: 0.8,
  vignettingBlur: 0.8
}
const PIXIFilters = {}
let directionPos = true

function init () {
  renderer = new PIXI.Renderer({
    width: window.innerWidth,
    antialias: false,
    height: window.innerHeight,
    resolution: 1,
    transparent: true
  })

  document.getElementById('app_container').appendChild(renderer.view)

  stage = new PIXI.Container()
  PIXIFilters.crt = new crt.CRTFilter({
    ...animateProps
  })
  PIXIFilters.rgb = new RGBSplit.RGBSplitFilter([100, 0], [10, 200], [50, 50])

  graphics = new PIXI.Graphics()

  // draw a rounded rectangle
  graphics.beginFill(0xFFFFFF, 1)
  graphics.drawRoundedRect(0, 0, window.innerWidth, window.innerHeight, 0)
  graphics.endFill()
  graphics.alpha = 0.5

  stage.filters = [PIXIFilters.rgb, PIXIFilters.crt]
  stage.addChild(graphics)
  renderer.render(stage)
}

function animate () {
  const newTime = Date.now()
  let deltaTime = newTime - oldTime
  oldTime = newTime
  if (deltaTime < 0) deltaTime = 0
  if (deltaTime > 1000) deltaTime = 1000
  const deltaFrame = deltaTime * 10 / 1000 // 1.0 is for single frame

  // update
  if (PIXIFilters.crt.seed > 1) {
    directionPos = false
  } else if (PIXIFilters.crt.seed < 0) {
    directionPos = true
  }

  PIXIFilters.crt.time += 0.1 * deltaFrame

  if (directionPos) {
    PIXIFilters.crt.seed += 0.001 * deltaFrame
  }
  if (!directionPos) {
    PIXIFilters.crt.seed -= 0.001 * deltaFrame
  }

  if (random(0.05)) {
    graphics.alpha = 0.4
  } else {
    graphics.alpha = 0.5
  }

  if (random(10)) {
    PIXIFilters.rgb.enabled = true
  } else {
    PIXIFilters.rgb.enabled = false
  }

  renderer.render(stage)

  requestAnimationFrame(animate)
}

function random (percentage) {
  const max = 10000
  const random = Math.floor(Math.random() * max)
  const threshold = max * (percentage / 100)

  if (random > threshold) {
    return false
  } else {
    return true
  }
}

export default function filters () {
  init()
  oldTime = Date.now()
  requestAnimationFrame(animate)
}
