import React from 'react'
import figlet from 'figlet'
import fontStop from 'figlet/importable-fonts/Stop'
import './styles.css'
import { gsap } from 'gsap'

figlet.parseFont('Stop', fontStop)

class Logo extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      logo: '',
      random: null
    }
  }

  componentDidMount () {
    const chars = ['A', 'k', 'o', 't', 'e', 'k']

    const builtWord = chars.map((char) => {
      let text = ''
      figlet.text(char, {
        font: 'Stop'
      }, (err, data) => {
        if (data) {
          text += '<div class="logo-text">' + data + '</div>'
          console.log(data)
        }
        err && console.log(err)
      })
      return text
    })

    const result = builtWord.toString().replace(/,/g, '')
    this.setState({ logo: result })

    this.setState({ random: Math.floor(Math.random() * 11) })
  }

  componentDidUpdate () {
    gsap.to('.logo-text', {
      duration: 0.5,
      color: 'rgba(255, 255, 255, 0.2)',
      transform: 'scale(1)',
      ease: 'steps(5)',
      stagger: {
        each: 0.1,
        repeatDelay: 0.7,
        from: 'start',
        grid: [1, 6],
        repeat: -1,
        yoyo: false
      }
    })
  }

  render () {
    return (
      <>
        <pre>
          <div className='logo' dangerouslySetInnerHTML={{ __html: this.state.logo }} />
        </pre>
        <p>
      Welcome to <span className='text-white'>Akotek Bulletin Board System</span>. We offer 10 nodes with 28 800 bps uplink. Current active connections <span className='text-white'>{this.state.random}/10</span>. You have <span className='text-white'>5/5</span> kB left to download.
        </p>
      </>
    )
  }
}

export default Logo
